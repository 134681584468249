<template>
    <div>
        <div class="page-title">
            <div class="title-left">
                <span>数据同步</span>
                <span class="file-name">{{ fileName }}</span>
                <button @click="cleanCache" v-if="fileName" class="close-btn"></button>
            </div>

            <div>
                <button class="btn btn-white" @click="openExcel">模板下载</button>

                <a-upload
                        :show-upload-list="false"
                        :action="action"
                        @change="handleChange"
                        :before-upload="beforeUpload"
                >
                    <button class="btn">导入数据</button>
                </a-upload>

            </div>
        </div>

        <div>
            <a-table
                    class="stock-table"
                    :columns="table.columns"
                    :data-source="table.data"
                    :loading="table.loading"
                    :pagination="table.pagination"
                    :rowKey="record => record.id || record.key"
                    @change="tableChanged"
                    bordered
            >
                <div slot="idx" slot-scope="val,item">
                    {{ (query.page -1) * 20 + table.data.indexOf(item) + 1 }}
                </div>
                <div class="thumb-img" slot="thumb" slot-scope="thumb">
                    <img :src="thumb" alt="">
                </div>
                <div slot="price_match" slot-scope="val,item">

                    <span :class="{cj:parseFloat(item.excel_price - item.shop_price) !== 0}">{{(item.excel_price - item.shop_price).toFixed(2)  }}</span>

                </div>

                <div slot="stock_match" slot-scope="val,item">
                    <span :class="{cj:parseFloat(item.excel_stock - item.shop_stock) !== 0}">{{(item.excel_stock - item.shop_stock).toFixed(0)  }}</span>
                </div>

                <div slot="select" slot-scope="val,item">
                    <button @click="changeSelected(item)" :class="{selected:item.selected}" class="select-btn"></button>
                </div>
            </a-table>
        </div>
        <div class="tools">
            <div :class="{mt16:!table.data.length}">同步记录：
                <span v-if="log">{{ log.created_at }}
                {{ log.name }} 同步
                {{ log.excel_num }}件商品，修改{{ log.edit_num }}件</span>
                <span v-else>无</span>
            </div>
            <div v-if="table.data.length">
                <button @click="selectAll()" class="btn btn-white">全选</button>
                <button @click="confirmSync()" class="btn">同步</button>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "../../../repo/api";
    import auth from "../../../auth";

    export default {
        name: "GoodsSync",
        data() {
            let appId = localStorage['mini_app_id'];

            return {
                table: {
                    columns: [
                        {
                            title: '选择',
                            dataIndex: 'select',
                            align: 'center',
                            width: '65px',
                            scopedSlots: {customRender: 'select'},
                        },
                        {
                            title: '序号',
                            dataIndex: 'idx',
                            align: 'center',
                            scopedSlots: {customRender: 'idx'},
                        },
                        {
                            title: '商品图片',
                            dataIndex: 'thumb',
                            align: 'center',
                            width: '96px',
                            scopedSlots: {customRender: 'thumb'},
                        },
                        {
                            title: '商品条码',
                            dataIndex: 'code',
                            align: 'center',
                        },
                        {
                            title: '实体价格',
                            dataIndex: 'excel_price',
                            align: 'center',
                        },
                        {
                            title: '商店价格',
                            dataIndex: 'shop_price',
                            align: 'center',
                        },
                        {
                            title: '价格差额',
                            dataIndex: 'price_match',
                            align: 'center',
                            scopedSlots: {customRender: 'price_match'},
                        },
                        {
                            title: '实体库存',
                            dataIndex: 'excel_stock',
                            align: 'center',
                        },
                        {
                            title: '商店库存',
                            dataIndex: 'shop_stock',
                            align: 'center',
                        },
                        {
                            title: '库存差额',
                            dataIndex: 'stock_match',
                            align: 'center',
                            scopedSlots: {customRender: 'stock_match'},
                        }
                    ],
                    loading: false,
                    data: [],
                    pagination: {
                        defaultCurrent: 1,
                        current: 1,
                        defaultPageSize: 10,
                        total: 1,
                        pageSize: 20
                    },
                },
                list: [],
                fileName: '',
                action: api.colUrl('/ast-shop/upload-stock-excel?token=' + auth.getToken() + '&_mini_app_id=' + appId),
                query: {
                    page: 1
                },
                log: {}
            }
        },
        mounted() {
            this.getHistory()
            this.getList()
        },
        methods: {
            getFirstList() {
                this.query.page = 1
                this.getList()
            },
            getList() {

                this.table.loading = true

                api.get('/ast-shop/stock-data', this.query, (data) => {

                    this.table.data = data.data.data
                    this.table.pagination.current = data.data.current_page
                    this.table.pagination.total = data.data.total
                    this.table.pagination.pageSize = data.data.per_page

                    this.table.loading = false

                })
            },
            selectAll() {
                this.$loading.show()

                api.post('/ast-shop/stock-select-all', {}, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) return;

                    this.table.data.forEach(item => {
                        item.selected = 1
                    })

                    this.$forceUpdate()
                })
            },
            confirmSync() {
                this.$loading.show()

                api.post('/ast-shop/confirm-stock', {}, (data) => {

                    this.$loading.hide()

                    this.$message.data(data)

                    if (data.code !== 0) return;

                    this.getFirstList()
                    this.getHistory()
                })
            },
            getHistory() {
                api.get('/ast-shop/stock-history', (data) => {

                    this.fileName = data.data.file_name
                    this.log = data.data.log

                })
            },
            changeSelected(item) {

                this.$loading.show()

                let select = item.selected ? 0 : 1;

                api.post('/ast-shop/stock-data-select', {select, id: item.id}, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) return;

                    item.selected = select
                    this.$forceUpdate()
                })
            },
            tableChanged(pagination) {
                this.query.page = pagination.current
                this.getList()
            },
            openExcel() {
                window.open('https://static.ycsd.work/bang/template.xlsx')
            },
            cleanCache() {
                this.$confirm({
                    title: '确定要清理所有导入数据吗?',
                    onOk: () => {
                        this.$loading.show()

                        api.post('/ast-shop/clear-stock-data', {}, (data) => {

                            this.$loading.hide()

                            if (data.code !== 0) return;

                            this.$message.success('清理数据成功！')

                            this.getFirstList()
                            this.getHistory()
                        })
                    }
                })
            },
            handleChange(info) {

                if (info.file.status === 'uploading') {
                    this.$loading.show()
                    return;
                }

                if (info.file.status === 'done') {

                    let response = info.file.response;

                    if (response.code !== 0) {
                        this.$loading.hide()
                        return this.$message.error(response.msg)
                    }

                    this.getHistory()
                    this.getFirstList()

                    this.$loading.hide()

                    this.$message.success('导入数据成功！')
                }
            },
            beforeUpload(file) {
                const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

                if (!isJpgOrPng) {
                    this.$message.error('你只能上传xlsx文件!');
                }
                const isLt1M = file.size / 1024 / 1024 < 1;
                if (!isLt1M) {
                    this.$message.error('文件必须小于1M!');
                }
                return isJpgOrPng && isLt1M;
            },
        }
    }
</script>

<style lang="less" scoped>

    .page-title {
        display: flex;
        justify-content: space-between;

        .btn-white {
            margin-right: 16px;
        }
    }

    .title-left {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .file-name {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-left: 24px;
    }

    .thumb-img {
        width: 96px;
        height: 96px;
        display: flex;
        background: #F5F5F5;
        align-items: center;
        align-content: center;
        justify-content: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .mt16 {
        margin-top: 16px;
    }

    .close-btn {
        width: 14px;
        height: 14px;
        margin-left: 24px;
        border: none;
        cursor: pointer;
        background: url("/assets/icons/close-btn-black.png");
        background-size: 14px;
    }

    .select-btn {
        width: 16px;
        height: 16px;
        background-color: white;

        border: 1px solid #EEEEEE;
        cursor: pointer;

        &:hover {
            border-color: #cdcdcd;
        }

        &.selected {
            background-image: url("/assets/icons/stock-selected.png");
            background-size: 16px;
            border: none;
        }
    }

    .stock-table {
        /deep/ tr td:nth-child(3) {
            padding: 0;
        }

        /deep/ .ant-pagination {
            float: none;
        }
    }


    .tools {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        .btn-white {
            margin-right: 16px;
        }
    }

    .cj {
        color: #FBB03B;
    }
</style>